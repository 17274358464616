import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from "prop-types"
import moment from 'moment'
import { Link } from "gatsby"
import Img from "gatsby-image"

import { CartContext } from '../../context/CartContext';

import { cartSubTotal } from '../../helpers/cart';
import { formatPrice } from '../../helpers/currency';

import MakeoverDayIllustration from '../../images/svgs/makeover-day.svg'
import SpecialGiftIllutration from '../../images/svgs/special-message.svg'

export default function CartHeader( props ) {

	const isInitialMount = useRef( true )
	const { cart, removeFromCart, triggerCartEvent } = useContext( CartContext )
	const [ cartVisible, showCart ] = useState(false)
	const [ active, setActive ] = useState(false)

	useEffect(() => {
		if ( isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			if ( triggerCartEvent || active ) {
				showCart( true )
			} else {
				showCart( false )
			}
		}
	}, [ triggerCartEvent, active ])

	const handleShowCart = ( isCartVisible ) => {
		setActive( isCartVisible )
		showCart( isCartVisible )
	}

	return (
		<div
			className={ `cart-header ${ props.isOpen || cartVisible ? 'open' : '' }` }
			onMouseOver={() => handleShowCart( true )}
			onFocus={() => handleShowCart( true )}
			onMouseLeave={() => handleShowCart( false )}
			role="menuitem"
			tabIndex={0}
			ref={ isInitialMount }>
			{ cart && cart.length > 0 ? (
				<>
					<div className="cart-header__header">
						Shopping Bag
					</div>
					<div className="cart-header__product-list">
						{ cart.map( ( product, i ) => (
							<React.Fragment key={ i }>
								{ product.__typename === "MakeoverDays" ? (
									<div className="cart-header__product">
										<Link to="/makeover-days" className="cart-header__product-link">
											<MakeoverDayIllustration className="cart-header__product-image cart-header__product-image--illustration" />
										</Link>
										<div className="cart-header__product-meta">
											<div className="cart-header__product-container">
												<Link to="/makeover-days" className="cart-header__product-link">
													<span className="cart-header__product-name">Makeover Day</span>
												</Link>
												<span className="cart-header__product-price">{ formatPrice( product.price ) }</span>
											</div>
											<span className="cart-header__product-info">{ moment( product.Date ).format("Do MMM YYYY") }</span>
											<span className="cart-header__product-info">Quantity: { product.quantity }</span>
											<button className="cart-header__product-remove" onClick={ () => removeFromCart( product, product.id ) }>delete item x</button>
										</div>
									</div>
								) : (
									product.giftCard ? (
										<div className="cart-header__product">
											<Link to={`/products/${ product.slug }`} className="cart-header__product-link">
												<SpecialGiftIllutration className="cart-header__product-image cart-header__product-image--illustration" />
											</Link>
											<div className="cart-header__product-meta">
												<div className="cart-header__product-container">
													<Link to={`/products/${ product.slug }`} className="cart-header__product-link">
														<span className="cart-header__product-name">Gift Voucher</span>
													</Link>
													<span className="cart-header__product-price">{ formatPrice( product.value ) }</span>
												</div>
												<span className="cart-header__product-info">Value: { formatPrice( product.value ) }</span>
												<span className="cart-header__product-info">Quantity: { product.quantity }</span>
												<button className="cart-header__product-remove" onClick={ () => removeFromCart( product, product.value ) }>delete item x</button>
											</div>
										</div>
									) : (
										<>
											{ product.quantity.map( ( variation, i ) => (
												<div className="cart-header__product" key={ i }>
													<Link to={`/products/${ product.slug }`} className="cart-header__product-link">
														<Img
															className="cart-header__product-image"
															fluid={ product.images[0].imageFile.childImageSharp.fluid } />
													</Link>
													<div className="cart-header__product-meta">
														<div className="cart-header__product-container">
															<Link to={`/products/${ product.slug }`} className="cart-header__product-link">
																<span className="cart-header__product-name">{ product.name }</span>
															</Link>
															<div className="cart-header__product-price-container">
																{ product.salePrice && 
																	<span className="cart-header__product-price-sale">{ formatPrice( product.salePrice ) }</span>
																}
																<span className={`cart-header__product-price ${ product.salePrice ? "disabled" : ''}`}>{ formatPrice( product.price ) }</span>
															</div>

														</div>
														{ product.colour_option && <span className="cart-header__product-info">{ product.colour_option.name }</span> }
														{ variation && variation.size && variation.size.size && <span className="cart-header__product-info">Size: { variation.size.size }</span> }
														<span className="cart-header__product-info">Quantity: { variation.quantity }</span>
														<button className="cart-header__product-remove" onClick={ () => removeFromCart( product, variation.variationId ) }>delete item x</button>
													</div>
												</div>
											) ) }
										</>
								) ) }
							</React.Fragment>
						) ) }
					</div>
					<div className="cart-header__meta">
						<div className="cart-header__totals-container">
							<div className="cart-header__total-container cart-header__total-container--subtotal">
								<span className="cart-header__total-detail">Subtotal:</span>
								<span className="cart-header__total-detail">{ formatPrice( cartSubTotal( cart ) ) }</span>
							</div>
							<div className="cart-header__total-container">
								<span className="cart-header__total-detail">Total:</span>
								<span className="cart-header__total-detail">{ formatPrice( cartSubTotal( cart ) ) }</span>
							</div>
						</div>
						<div className="cart-header__button-container">
							<Link to="/bag" className="button button--primary" onClick={() => handleShowCart(false)}>
								View Bag
							</Link>
							<Link to="/checkout" className="button button--primary" onClick={() => handleShowCart(false)}>
								Checkout Now
							</Link>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="cart-header__header">
						0 items in bag
					</div>
					<div className="cart-header__product-list">
						<span className="cart-header__empty">Your bag is empty</span>
					</div>
				</>
			) }
		</div>
	)
}

CartHeader.propTypes = {
	isOpen: PropTypes.bool,
}