export const GTAddToCart = ( product, quantity, type ) => {

	if ( window !== undefined && window.gtag !== undefined ) {
		const productModel = generateProductModel( product, quantity, type )

		window.gtag( 'event', 'add_to_cart', {
			items: [
				productModel
			]
		} )
	}
}

export const GTRemoveFromCart = ( product, quantity, type ) => {

	if ( window !== undefined && window.gtag !== undefined ) {
		const productModel = generateProductModel( product, quantity, type )

		window.gtag( 'event', 'remove_from_cart', {
			items: [
				productModel
			]
		} )
	}
}

export const GTViewItem = product => {

	if ( window !== undefined && window.gtag !== undefined ) {
		window.gtag( 'event', 'view_item', {
			items: [
				{
					item_id: product?.id,
					item_name: product?.name,
					item_brand: product?.brand?.name,
					item_category: product?.sub_category?.label,
					item_variant: product?.colour_option?.name,
					price: product?.salePrice ? product.salePrice : product?.price,
					currency: 'GBP'
				}
			]
		} )
	}
}

export const GTViewCart = cart => {

	if ( window !== undefined && window.gtag !== undefined ) {
		let items = []

		cart.forEach( product => {
			let productModel

			if ( product.__typename === "MakeoverDays" ) {
				productModel = generateProductModel( product, product.quantity, 'makeover' )
			} else if ( product.giftCard ) {
				productModel = generateProductModel( product, product.quantity, 'giftcard' )
			} else {
				// If there are multiple variations of the product, add up the total quantity
				// otherwise just use the only one in the array [ 0 ]
				if ( product.quantity.length > 1 ) {
					let productQuantity = 0

					product.quantity.forEach( item => {
						productQuantity += item.quantity
					} )

					productModel = generateProductModel( product, productQuantity, 'product' )
				} else {
					productModel = generateProductModel( product, product.quantity[ 0 ].quantity, 'product' )
				}
			}

			items.push( productModel )
		} )

		window.gtag( 'event', 'view_cart', {
			items
		} )
	}
}

export const GTPurchase = ( cart, gtData ) => {

	if ( window !== undefined && window.gtag !== undefined ) {

		let items = []

		cart.forEach( product => {
			let productModel

			if ( product.__typename === "MakeoverDays" ) {
				productModel = generateProductModel( product, product.quantity, 'makeover' )
			} else if ( product.giftCard ) {
				productModel = generateProductModel( product, product.quantity, 'giftcard' )
			} else {
				// If there are multiple variations of the product, add up the total quantity
				// otherwise just use the only one in the array [ 0 ]
				if ( product.quantity.length > 1 ) {
					let productQuantity = 0

					product.quantity.forEach( item => {
						productQuantity += item.quantity
					} )

					productModel = generateProductModel( product, productQuantity, 'product' )
				} else {
					productModel = generateProductModel( product, product.quantity[ 0 ].quantity, 'product' )
				}
			}

			items.push( productModel )
		} )

		window.gtag( 'event', 'purchase', {
			currency: 'GBP',
			transaction_id: gtData.transactionId,
			value: gtData.value,
			shipping: gtData.postagePrice,
			items
		} )
	}
}

const generateProductModel = ( product, quantity, type ) => {
	let model = {}

	if ( type === 'makeover' ) {
		model = {
			item_id: `makeover_${ product.id }`,
			item_name: 'Makeover Day',
			item_category: 'Makeover Day',
			quantity,
			price: product?.price,
			currency: 'GBP'
		}
	}

	if ( type === 'giftcard' ) {
		model = {
			item_id: `voucher_${ product.id }`,
			item_name: !product?.isMakeoverVoucher ? 'Gift Voucher' : product?.isDiamondVoucher ? 'Diamond Makeover Voucher' : 'Standard Makeover Voucher',
			item_category: 'Gift Voucher',
			quantity,
			price: product?.value,
			currency: 'GBP'
		}
	}

	if ( type === 'product' ) {
		model = {
			item_id: product?.id,
			item_name: product?.name,
			item_brand: product?.brand?.name,
			item_category: product?.sub_category?.label,
			item_variant: product?.colour_option?.name,
			quantity,
			price: product?.salePrice ? product.salePrice : product?.price,
			currency: 'GBP'
		}
	}

	return model
}