import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import SubNavigation from './SubNavigation'

import Sunflower from '../../images/svgs/sunflower.svg'

export default function Navigation( props ) {

	const { toggleMenu, menuToggled, width } = props

	const queryData = useStaticQuery(graphql`
		query CategoryNavQuery {
			strapi {
				navigation {
					navItems {
						id
						label
						url
						subNavItems {
							id
							label
							url
						}
					}
				}
			}
		}
	`)

	const data = queryData.strapi.navigation.navItems

	return (
		<div className={`navigation ${ menuToggled ? 'is-open' : '' }`}>
			<nav className="navigation__menu">
				<div className="navigation__wrapper">
					<ul className="navigation__list">
						{ data.map( navItem => (
							<React.Fragment key={ navItem.id }>
								{ navItem.subNavItems.length ? (
									<SubNavigation navItem={ navItem } toggleMenu={ toggleMenu } menuToggled={ menuToggled } width={ width } />
								) : (
									<li className="navigation__item">
										<Link
											to={ navItem.url }
											className="navigation__link"
											activeClassName="active"
											onClick={() => toggleMenu( false ) }>
											{ navItem.label }
										</Link>
									</li>
								) }
							</React.Fragment>
						) ) }
						<li className="navigation__item u-hide-tablet">
							<Link
								to="/account"
								className="navigation__link"
								activeClassName="active"
								onClick={() => toggleMenu( false ) }>
								My Account
							</Link>
						</li>
					</ul>
					<div className="navigation__illustration-container">
						<Sunflower className="navigation__illustration u-show-tablet" />
					</div>
					<button
						className={`navigation__close-container u-show-tablet ${ menuToggled ? '' : 'u-hide' }`}
						onClick={ () => toggleMenu( false ) }>
						<span className="navigation__close">Close</span>
					</button>
				</div>
			</nav>
		</div>
	)
}