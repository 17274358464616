import React, { useReducer, useEffect, useState } from 'react'
import axios from 'axios'

import AuthContext from './AuthContext';
import authReducer from './authReducer';

export default function AuthProvider({ children }) {

	const DEFAULT_STATE = {
		user: {},
		loggedIn: false
	}

	const [ state, dispatcher ] = useReducer( authReducer, DEFAULT_STATE )
	const [ loadingUser, setLoadingUser ] = useState( true )

	// Checks if user exists in local storage, if not render intial state.
	const checkStorageForUser = async () => {

		if ( typeof window === 'undefined' ) {
			return DEFAULT_STATE;
		}

		const userInStorage = JSON.parse( localStorage.getItem( 'user' ) ) ? JSON.parse( localStorage.getItem( 'user' ) ) : false

		if ( userInStorage ) {
			try {
				const userIdParam = userInStorage.user.id
	
				const { data: payload } = await axios.get( `/api/users/${ userIdParam }` );

				dispatcher( { type: 'LOGIN', payload } )
				setLoadingUser( false )
			} catch( e ) {
				dispatcher( { type: 'LOGOUT' } )
				setLoadingUser( false )
			}
		} else {
			dispatcher( { type: 'LOGOUT' } )
			setLoadingUser( false )
		}
	};

	useEffect(() => {
		checkStorageForUser()
	}, [])

	return (
		<AuthContext.Provider value={{ state, dispatcher, loadingUser }}>
			{children}
		</AuthContext.Provider>
	)
}
