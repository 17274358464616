export const saveCart = ( cart ) => {
	localStorage.setItem( 'cart', JSON.stringify( cart ) );
};

export const getCart = () => {

	if ( typeof window === 'undefined' ) {
		return [];
	}

	const cart = JSON.parse( localStorage.getItem( 'cart' ) ) ? JSON.parse( localStorage.getItem( 'cart' ) ) : [];

	return cart;
};

export const cartSubTotal = ( cart ) => {
	// Sum up all the individual product costs
	const subTotal = cart.reduce( ( counter, product ) => {

		// if product is makeoverDay
		if ( product.__typename === "MakeoverDays" ) {
			return counter + product.price * product.quantity
		}

		if ( product.giftCard ) {

			if ( product.code ){
				// this should never be called
				return counter
			}

			return counter + product.value * product.quantity
		}

		// Sum up all variation of product
		const sum = product.quantity.reduce( ( variationCounter, variation ) => {

			if ( product.salePrice ) {
				return variationCounter + variation.quantity * product.salePrice
			} else {
				return variationCounter + variation.quantity * product.price
			}

		}, 0 )

		return counter + sum

	}, 0 )
	
	return Math.round( subTotal * 100 ) / 100;
}

export const cartDiscount = ( cart ) => {

	if ( !cart.length ){
		return 0;
	}

	let subTotal = cart.reduce( ( counter, giftCard ) => { 
		return counter + giftCard.amountOnCard
	}, 0)

	return subTotal
}

export const cardHolderDiscount = ( cart, purpleCardHolder = false, staffDiscount = false ) => {

	if ( !cart.length ) {
		return 0;
	}

	let percentageOff = 0;

	if ( purpleCardHolder ) {
		// 10%
		percentageOff = .1
	}

	if ( staffDiscount ) {
		// 50%
		percentageOff = .5
	}
	// Check whether a item is on sale
	let salesItemTotal = 0;

	const subTotal = cart.reduce( ( counter, product ) => {

		// if product is makeoverDay
		if ( product.__typename === "MakeoverDays" || product.giftCard ) {
			return counter
		}

		// Sum up all variation of product
		const sum = product.quantity.reduce( ( variationCounter, variation ) => {

			if ( product.salePrice ) {
				salesItemTotal += variation.quantity * product.salePrice
				return variationCounter
			} else {
				return variationCounter + variation.quantity * product.price
			}
		}, 0 )

		return counter + sum
	}, 0 )

	const total = Math.round( subTotal * 100 ) / 100;

	let discountTotal = Math.round( total * percentageOff * 100 ) / 100

	// staff get 25% off sale items
	if ( staffDiscount ) {
		const discountOnSaleItems =  Math.round( salesItemTotal  * .25 * 100 ) / 100
		discountTotal += discountOnSaleItems
	}

	return discountTotal
}

export const cartTotal = ( cart, postage, discount = 0 ) => {
	if ( !cart.length ) {
		return 0;
	}

	const subTotal = cartSubTotal( cart );
	const shipping = postage.price;
	if( discount > ( subTotal + shipping ) ) {
		discount = ( subTotal + shipping ) 
	}
	
	const total = subTotal + shipping - discount;

	return Math.round( total * 100 ) / 100;
}

export const calculateTotalDiscount = ( cart, postage, giftCards, purpleCardMember, staffDiscount ) => {
	let discount = 0

	if ( purpleCardMember || staffDiscount ) {
		const cardMemberDiscount = cardHolderDiscount( cart, purpleCardMember, staffDiscount )

		discount += cardMemberDiscount
	}

	if ( giftCards ) {
		const giftCardDiscount = cartDiscount( giftCards ) < cartTotal( cart, postage, discount ) ? cartDiscount( giftCards ) : cartTotal( cart, postage, discount )

		discount += giftCardDiscount
	}

	return discount
}