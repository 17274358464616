import React from 'react'
import { ApolloProvider } from '@apollo/client';

import { client } from './client';

import AuthContextProvider from './src/context/auth/AuthState'
import CartContextProvider from './src/context/CartContext'
import CategoryContextProvider from './src/context/CategoryContext'

import CookiePolicy from './src/components/Cookie/CookiePolicy'

import Layout from './src/components/Layout/Layout'

import './src/styles/toolkit.scss'

export const shouldUpdateScroll = () => {
	// Get the user-agent string 
	let userAgentString = navigator.userAgent;

	// Detect Safari
	let safariAgent = userAgentString.indexOf("Safari") > -1;
	// Detect Chrome
	let chromeAgent = userAgentString.indexOf("Chrome") > -1;

	// Discard Safari since it also matches Chrome 
	if ((chromeAgent) && (safariAgent)) safariAgent = false;

	if ( safariAgent ) {
		setTimeout(()=> {
			window.scrollTo(0, 0)
		} )
	}

	return true
}

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={ client }>
		<AuthContextProvider>
			<CartContextProvider>
				<CategoryContextProvider>
					<Layout>
						{ element }
						<CookiePolicy />
					</Layout>
				</CategoryContextProvider>
			</CartContextProvider>
		</AuthContextProvider>
	</ApolloProvider>
)