import React, { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'gatsby'

import Navigation from '../Navigation/Navigation'
import CartHeader from '../Navigation/Cart'

import SiteLogo from '../../images/svgs/dream-on-logo.svg'
import CartIcon from '../../images/svgs/cart-icon.svg'

import { HiOutlineUser } from 'react-icons/hi'

import { CartContext } from '../../context/CartContext'

import { MEASUREMENTS } from '../../helpers/constants'

export default function Header () {

	const RESIZE_DEBOUNCE = 150;
	const headerRef = useRef()

	const getWidth = () => headerRef.current.offsetWidth;

	const { cart } = useContext( CartContext );
	const [ cartVisible, showCart ] = useState(false);
	const [ menuToggled, toggleMenu ] = useState(false)
	const [ width, setWidth ] = useState( 0 )

	useEffect( () => {
		if ( headerRef ) {
			setWidth( headerRef.current.offsetWidth )
		}

		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout( timeoutId );
			// change width from the state object after 150 milliseconds
			timeoutId = setTimeout( () => setWidth( getWidth() ), RESIZE_DEBOUNCE );
		};
		
		// set resize listener
		window.addEventListener( 'resize', resizeListener );

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener( 'resize', resizeListener );
		};
	}, [] );

	useEffect(() => {
		if ( menuToggled ) {
			document.body.classList.add('u-body-no-scroll')
		} else {
			document.body.classList.remove('u-body-no-scroll')
		}
	}, [ menuToggled ] )

	const onHover = ( trigger ) => {
		if ( window.innerWidth > MEASUREMENTS.TABLET ) {
			trigger( true )
		}
	}

	const getCartTotalProducts = ( cart ) => {
		const total = cart.reduce( ( counter, product ) => {

			if ( product.__typename === "MakeoverDays" ) {
				return counter + product.quantity
			}

			if ( product.giftCard ) {
				return counter + product.quantity
			}

			const sumOfVariations = product.quantity.reduce( ( counter, variation ) => {
				return counter + variation.quantity
			}, 0)

			return counter + sumOfVariations;
	
		}, 0 )
	
		return total
	}

	return (
		<header className="header" ref={ headerRef }>

			<div className="header__container">

				<button className="header__mobile-menu-toggle u-show-tablet" onClick={ () =>toggleMenu( !menuToggled ) }>
					<div className={`hamburger ${ menuToggled ? 'active' : '' }`}>
						<span className="hamburger__box">
							<span className="hamburger__inner"></span>
						</span>
					</div>
				</button>

				<Link to="/" className="header__title-link">
					<SiteLogo className="header__logo" />
				</Link>

				<div className="header__navigation-container">
					<Navigation toggleMenu={ toggleMenu } menuToggled={ menuToggled } width={ width } />

					<div className={`navigation__meta ${ cart && cart.length ? 'active' : '' }`}>

						<Link to="/account" className="navigation__meta-link u-show-tablet">
							<HiOutlineUser className="navigation__cart-icon navigation__cart-icon--account" />
						</Link>

						<button
							className="navigation__cart-container"
							onClick={() => showCart( !cartVisible )}
							onMouseEnter={ () => onHover( showCart ) }
							onMouseLeave={() => showCart( false )}
							tabIndex={0} >
							<CartIcon className="navigation__cart-icon"/>
							{ cart && cart.length > 0 &&
								<span className={`navigation__cart-quantity ${ getCartTotalProducts( cart ) > 9 ? 'navigation__cart-quantity--large' : '' }`}>
									{ getCartTotalProducts( cart ) }
								</span>
							}
						</button>

						<CartHeader isOpen={ cartVisible } />
					</div>
				</div>

			</div>
		</header>
	)
}
