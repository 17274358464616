import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'

import SubFooter from './SubFooter'

import Flowers from '../../images/flowers.png'
import SocialInsta from '../../images/svgs/social-icon-instagram.svg'
import SocialFacebook from '../../images/svgs/social-icon-facebook.svg'

export default function Footer() {

	const data = useStaticQuery(graphql`
		query getOpeningTimes {
			strapi {
				openingTime {
					dateTime
				}
			}
		}
	`)

	const openingTimes = data.strapi.openingTime.dateTime

	return (
		<footer className="footer" style={{ backgroundImage: `url(${Flowers})` }}>
			<SubFooter />
			<div className="footer__wrapper">
				<div className="footer__content-container">
					<div className="footer__info-container">
						<div className="footer__info">dream on
							<a className="footer__icon-link" href="https://www.instagram.com/clothing_dream_on/" target="_blank" rel="noreferrer">
								<SocialInsta className="footer__info-icon" />
							</a>
							<a className="footer__icon-link" href="https://www.facebook.com/DreamOnSuffolk" target="_blank" rel="noreferrer">
								<SocialFacebook className="footer__info-icon"/>
							</a>
						</div>
						<div className="footer__info">Personal attention for you</div>
					</div>
					<div className="footer__info-container">
						<div className="footer__info footer__info--margin">Shop opening hours</div>
						{ openingTimes && <div className="footer__info">{ openingTimes }</div> }
						<div className="footer__info--margin"><a href="tel:01379 678483" className="footer__link">01379 678483</a></div>
					</div>
					<div className="footer__info-container">
						<a href="https://goo.gl/maps/6XA41H86o6YRE7gz8" target="_blank" rel="noreferrer" className="footer__info footer__info-address footer__info--margin">13 Castle Street, Eye</a>
						<a href="https://goo.gl/maps/6XA41H86o6YRE7gz8" target="_blank" rel="noreferrer" className="footer__info footer__info-address">Suffolk, IP23 7AN</a>
					</div>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Customer Services</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/contact-us">Contact Us</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/privacy-policy">Cookie &amp; Privacy Policy</Link></li>
						{/* <li className="footer__list-item"><Link className="footer__link" to="#!">FAQs</Link></li> */}
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">Shop with us</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/makeover-days">Book your Makeover Day</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/delivery-and-returns">Delivery &amp; Returns</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/terms-and-conditions">Terms & Conditions</Link></li>
						{/* <li className="footer__list-item"><Link className="footer__link" to="#!">Gift Vouchers</Link></li> */}
					</ul>
				</div>
				<div className="footer__content-container">
					<div className="footer__info-title">About</div>
					<ul className="footer__list">
						<li className="footer__list-item"><Link className="footer__link" to="/about-us">Our Story</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/about-us/our-founder">Our Founder</Link></li>
						<li className="footer__list-item"><Link className="footer__link" to="/about-us/our-family">Our Family</Link></li>
						<li className="footer__list-item"><Link to="/about-us#team-members" className="footer__link">Our Team</Link></li>
					</ul>
				</div>
			</div>
		</footer>
	)
}
