import React, { useState } from 'react';
import axios from 'axios'

import Chevron from '../../images/svgs/chevron.svg';

export default function SubFooter() {

	const [ email, setEmail ] = useState( '' )
	const [ submitted, setSubmitted ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault();
		const hidden = document.querySelector('#emailInput')

		if ( hidden.value === "" ) {
			const data = { subscribersEmail: email }
			await axios.post( '/api/subscribers', data )

			setEmail('')
			setSubmitted( true )
		}
	}
	
	return (
		<div className="sub-footer">
			<div className="sub-footer__wrapper wrapper">

				<div className="sub-footer__text">Lets keep in touch. Sign up to our newsletter to hear from us.</div>

				<div className="sub-footer__form-container">
					{ !submitted ? (
						<form action="" className="sub-footer__form" onSubmit={ handleSubmit }>
							<input
								type="email"
								className="sub-footer__form-text-field"
								value={ email }
								placeholder="Enter your email address"
								onChange={ e => { setEmail( e.target.value ) } } />
							
							<button type="submit" className="sub-footer__form-submit">
								<Chevron className="sub-footer__icon" />
							</button>
							<input type="hidden" id="emailInput" name="" value="" />
						</form>
					) : (
						<div className="sub-footer__confirmation">
							Thank you for subscribing
						</div>
					) }
				</div>
			</div>
		</div>
	)
}