import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import { CartContext } from '../../context/CartContext'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Layout = ({ children }) => {

	const { validateCart } = useContext( CartContext )

	useEffect(() => {
		validateCart()
	}, [])
	
	return (
		<>
			<Header />
			<main className="main-content">
				<div className="body-content">
					{children}
					<Footer />
				</div>
			</main>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout