import React, { createContext, useState } from 'react';

import { getCart, saveCart } from '../helpers/cart'
import { GTAddToCart, GTRemoveFromCart } from '../helpers/gtagEvents'

export const CartContext = createContext( null );

export default ( { children } ) => {

	const [ cart, setCart ] = useState( getCart() );
	const [ postage, setPostage ] = useState({ price: 0 });
	const [ triggerCartEvent, setTriggerCartEvent ] = useState( false )
	const [ redeemedGiftCards, setRedeemedGiftCards ] = useState( [] )

	const updateCart = ( updatedCart ) => {
		setCart( updatedCart );
		saveCart( updatedCart );
	}

	const addToCart = ( product, quantity = 1, variationId, show = true ) => {
		// Get initial state of cart
		const cartCopy = [ ...cart ]

		if ( product.__typename === "MakeoverDays" ) {
			
			const indexOfProduct = cartCopy.findIndex( ( alreadyInCart ) => alreadyInCart.__typename && alreadyInCart.id === product.id )

			if ( indexOfProduct !== -1 ) {
				cartCopy[ indexOfProduct ].quantity += parseInt( quantity )

				if ( cartCopy[ indexOfProduct ].quantity >= product.spaceAvailable ) {
					cartCopy[ indexOfProduct ].quantity = product.spaceAvailable
				}

				if ( cartCopy[ indexOfProduct ].quantity === 0 ) {
					cartCopy.splice( indexOfProduct, 1 )
				}

			} else {
				product.quantity = quantity
				cartCopy.push( product )
			}

			GTAddToCart( product, quantity, 'makeover' )

		} else if ( product.giftCard ) {

			const indexOfGiftCard = cartCopy.findIndex( ( alreadyInCart ) => alreadyInCart.giftCard && alreadyInCart.value === product.value )

			if ( indexOfGiftCard !== -1 ){
				cartCopy[ indexOfGiftCard ].quantity += parseInt( quantity )
				if ( cartCopy[ indexOfGiftCard ].quantity === 0 ) {
					cartCopy.splice( indexOfGiftCard, 1 )
				}
			} else {
				product.quantity = quantity
				cartCopy.push( product )
			}

			GTAddToCart( product, quantity, 'giftcard' )

		} else {
			// item is product
			const indexOfProduct = cartCopy.findIndex( alreadyInCart => !alreadyInCart.__typename && !alreadyInCart.giftCard && alreadyInCart.id === product.id );
			const variationOfItem = product.variationsAndQuantities.find( node => node.id === variationId )

			if ( indexOfProduct !== -1 ) {
				// find index of the variation
				let indexOfVariation = cartCopy[ indexOfProduct ].quantity.findIndex( size => size.variationId === variationId )

				// Update the quantity
				if ( indexOfVariation !== -1 ){
					cartCopy[ indexOfProduct ].quantity[ indexOfVariation ].quantity += parseInt( quantity );
				} else {
					cartCopy[ indexOfProduct ].quantity.push( { variationId, quantity } )
				}
		
				// get the new index
				indexOfVariation = cartCopy[ indexOfProduct ].quantity.findIndex( size => size.variationId === variationId )

				// Add quantityInStock and size to the variation, makes it easier in the Cart.js
				cartCopy[ indexOfProduct ].quantity[ indexOfVariation ].quantityInStock = variationOfItem.quantity
				cartCopy[ indexOfProduct ].quantity[ indexOfVariation ].size = variationOfItem.size
				
				if ( cartCopy[ indexOfProduct ].quantity[ indexOfVariation ].quantity >= variationOfItem.quantity ) {
					cartCopy[ indexOfProduct ].quantity[ indexOfVariation ].quantity = variationOfItem.quantity;
				}

				if ( cartCopy[ indexOfProduct ].quantity[ indexOfVariation ].quantity === 0 ) {
					// Removes variation from cart
					cartCopy[ indexOfProduct ].quantity.splice( indexOfVariation, 1 )
					// Remove item from cart when quantity is 0
					if ( !cartCopy[ indexOfProduct ].quantity ) {
						cartCopy.splice( indexOfProduct, 1 );
					}
				}
		
			} else {
				product.quantity = [{
					quantity,
					variationId,
					quantityInStock: variationOfItem.quantity,
					size: variationOfItem.size }];
				// Push the product to the cart
				cartCopy.push( product );
			}

			GTAddToCart( product, quantity, 'product' )
		}
	
		updateCart( cartCopy );
		setTriggerCartEvent( show )

		setTimeout(() => {
			setTriggerCartEvent( false )
		}, 2000)
	}

	const removeFromCart = ( product, variationId ) => {
		// Get initial state of cart
		const cartCopy = [ ...cart ]
		let indexOfProduct, productToRemove, productType, productQuantity

		if ( product.__typename === "MakeoverDays" ) {
			indexOfProduct = cartCopy.findIndex( alreadyInCart => alreadyInCart.__typename && alreadyInCart.id === product.id )
			productToRemove = cartCopy[ indexOfProduct ]
			productType = 'makeover'
			productQuantity = product?.quantity

			cartCopy.splice( indexOfProduct, 1 )

		} else if ( product.giftCard ) {
			indexOfProduct = cartCopy.findIndex( alreadyInCart => alreadyInCart.giftCard && alreadyInCart.value === product.value )
			productToRemove = cartCopy[ indexOfProduct ]
			productType = 'giftcard'
			productQuantity = product?.quantity

			cartCopy.splice( indexOfProduct, 1 )

		} else {
			indexOfProduct = cartCopy.findIndex( alreadyInCart => !alreadyInCart.__typename && !alreadyInCart.giftCard && alreadyInCart.id === product.id )
			productToRemove = cartCopy[ indexOfProduct ]
			productType = 'product'

			const indexOfVariation = cartCopy[ indexOfProduct ].quantity.findIndex( size => size.variationId === variationId )
			productQuantity = productToRemove.quantity[ indexOfVariation ].quantity

			// Remove variation from the item
			cartCopy[ indexOfProduct ].quantity.splice( indexOfVariation, 1 )
			// Remove item from cart when quantity is no variations of it in the bag
			if ( !cartCopy[ indexOfProduct ].quantity.length ) {
				cartCopy.splice( indexOfProduct, 1 )
			}
		}

		updateCart( cartCopy )
		GTRemoveFromCart( productToRemove, productQuantity, productType ) // Trigger gtag event
	}

	const clearCart = () => {
		const emptyCart = []
		updateCart( emptyCart )
		setRedeemedGiftCards( [] )
	}

	/**
	 * Loops through the cart on page load to remove any items that don't have valid sizes - for users that may have added items before the fix
	 */
	const validateCart = () => {

		cart.forEach( product => {

			if ( product.__typename !== "MakeoverDays" && !product.giftCard ) {
				product.quantity.forEach( item => {
					if ( !item.size ) {
						removeFromCart( product, item.variationId )
					}
				} )
			}
		} )
	}

	return (
		<CartContext.Provider value={{ cart, postage, setPostage, addToCart, removeFromCart, clearCart, triggerCartEvent, redeemedGiftCards, setRedeemedGiftCards, validateCart }}>
			{ children }
		</CartContext.Provider>
	)
}