import React, { createContext, useState } from 'react';

import { getCategory, saveCateogry } from '../helpers/category'

export const CategoryContext = createContext( null );

export default ( { children } ) => {

	const [ category, setCategory ] = useState( getCategory() )
	const [ globalSort, setGlobalSort ] = useState( { label: 'New In', sort: 'created_at:DESC' } )

	const updateCategory = updatedCategory => {
		setCategory( updatedCategory );
		saveCateogry( updatedCategory );
	}

	const clearCategory = () => {
		const emptyCategory = []
		updateCategory( emptyCategory )
	}

	return (
		<CategoryContext.Provider value={{ category, clearCategory, updateCategory, globalSort, setGlobalSort }}>
			{ children }
		</CategoryContext.Provider>
	)
}